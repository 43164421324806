@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
:root{
  /* FONT FAMILY */
  /* --fontFamily-open-sans: 'Open Sans', sans-serif; */

  /* BORDER COLOUR */
  --border-blue-jeans: hsla(202, 100%, 50%, 1);
  --border-white-alpha-10: hsla(0, 0%, 100%, 0.1);

  /* OTHER */
  --body-color-grey: #F1F0F6;
  --bar-color-light: #FFF;
  --golden-color-txt: gold;
  --tertiary-color-dark-blue: #7977F5;
  --non-color-dark: #8D8D8D;
  --toggle-color: #DDD;
  --text1-color-dark-grey: #000;
  --input-text-color: #CCC;
  --alert-color-red: #FC3B56;
  --border: 1px solid var(--non-color-dark);
  --box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;;
  --box-shadow-second: 0 .5rem 1rem rgba(0, 0, 0, .1);
  --text-shadow: 0 1.5rem 3rem rgba(0, 0, 0, .3);

  --tran-04: all 0.4s ease;
  --tran-06: all 0.6s ease;
  --tran-10: all 1.0s ease;
}
*, ::before, ::after{
  margin: 0;
  padding: 0;
  box-sizing: 0;
}
* {
  font-family: "Quicksand";
  margin: 0;
  font-size: 20px;
  line-height: 40px;
  color: var(--bar-color-light);
  font-weight: 600;
  box-sizing: border-box;
}
a{
  text-decoration: none;
  color: var(--text1-color-dark-grey);
}
i{
  font-weight: 400;
  color: var(--bar-color-light);
  font-size: 28px;
}
img, span, button{
  display: block;
  cursor: pointer;
}
h1{
  font-size: 45px;
  padding: 0 2rem;
}h2{
  font-size: 35px;
}h3{
  font-size: 25px;
}h4{
  font-size: 20px;
}h5{
  font-size: 15px;
}
button{
  background-image: url(./img/gif7.gif);
  color: var(--bar-color-light);
  border: 2px solid var(--body-color-grey);
  padding: 2px 10px;
  border-radius: 10px;
  text-transform: uppercase;
  transition: var(--tran-06);
}
button:hover{
  transform: scale(0.89);
  color: var(--tertiary-color-dark-blue);
  background: var(--bar-color-light);
  border-color: var(--alert-color-red);
}
input, button, textarea{
  font: inherit;
}
html{
  font-family: var(--fontFamily-open-sans);
  font-size: 18px;
  scroll-behavior: smooth;
}
html::-webkit-scrollbar{
  width: 4px;
}
html::-webkit-scrollbar-track{
  background: #f1f1f1;
}
html::-webkit-scrollbar-thumb{
  background: #888;
}
html::-webkit-scrollbar-thumb:hover{
  background: #555;
}
body{
  background-image: url(./img/gif1.gif);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: var(--text1-color-dark-grey);
  line-height: 1.5;
}
.AllPage{
  padding: 6rem 0;
}
header .NBBox{
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  width: 100%;
  top: 0;
  height: 80px;
  background-image: url(./img/gif3.gif);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
}
.NBBox img{
  width: 70px;
  height: 70px;
  transition: var(--tran-04);
}
.NBBox img:hover{
  transform: rotateY(360deg);
}
.NBBox .NBOption{
  display: flex;
  list-style: none;
  gap: 20px;
  position: relative;
}
.NBOption li{
  border-bottom: none;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: unset;
  transition: var(--tran-04);
  cursor: pointer;
  color: transparent;
}
.NBOption li:hover{
  border-bottom: var(--bar-color-light);
  border-bottom-style: solid;
  transform: scale(0.89);
}
.NBOption h5{
  color: var(--bar-color-light);
}
.NBBox .NBBtn{
  display: flex;
  gap: 20px;
}
.NBBox .MenuBtn{
  display: none;
  pointer-events: none;
}
.homePage{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.homePage .Pt1{
  position: relative;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
}
.Pt1 h1{
  font-weight: 800;
  text-align: center;
  color: var(--bar-color-light);
  line-height: 1.3;
}
.Pt1 .btnHome{
  display: flex;
  gap: 25px;
  justify-content: center;
}
.Pt1 span{
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
}
.Pt1 .SCContainer{
  display: flex;
  gap: 2px;
  justify-content: center;
  flex-wrap: wrap;
}
.SCContainer span img{
  border: 1px solid transparent;
  padding: 5px;
  width: 45px;
  height: auto;
}
.SCContainer span img:hover{
  border-color: var(--body-color-grey);
  padding: 5px;
  transform: scale(0.85);
  border-radius: 5px;
}
.logo2{
  width: 200px;
  height: 200px;
  transition: var(--tran-04);
  animation: rotate 15s linear infinite;
}
@keyframes rotate {
  from{
    transform: rotate(0deg);
  } to{
    transform: rotateY(360deg);
  }
}
.Pt1 h2{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 0 0.5rem;
}
.homePage .Pt2{
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.Pt2 img{
  width: 500px;
  height: 500px;
  border-radius: 20px;
  transition: var(--tran-04);
  padding: 0 0.5rem;
}
.Pt2 img:hover{
  transform: scale(0.98);
}
.overviewPage{
  display: grid;
  position: relative;
  justify-content: center;
}
.overviewPage .OVPH1{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.OVPH1 span{
  background: var(--tertiary-color-dark-blue);
  width: 90px;
  height: 8px;
  border-radius: 15px;
}
.OVPH1 h1{
  color: var(--golden-color-txt);
  text-transform: uppercase;
}
.overviewPage .OVH34{
  text-align: center;
  padding: 0 3rem;
}
.OVH34 h2{
  line-height: 2.5;
  text-transform: uppercase;
}
.OVH34 .OVHDD{
  margin-top: 5px;
}
.TokenPage{
  height: 70vh;
}
.TokenPage .OVPH1{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.TokenPage .TPH4{
  padding-left: 6rem;
  margin-top: 40px;
}
.allocationPage .OVPH1{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.allocationPage .AllocImg{
  display: flex;
  justify-content: center;
  align-items: center;
}
.AllocImg img{
  margin-top: 40px;
  width: 900px;
  height: auto;
  border-radius: 20px;
  transition: var(--tran-04);
}
.AllocImg img:hover{
  transform: scale(0.98);
}
.RMapPage .OVPH1{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.RMapPage .RMpic{
  display: flex;
  justify-content: center;
  align-items: center;
}
.RMpic img{
  width: 900px;
  height: auto;
  transition: var(--tran-04);
}
.RMpic img:hover{
  transform: scale(0.98);
}
.findUsPage .OVPH1{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.findUsPage .allScLogo{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background: var(--text1-color-dark-grey);
  border-radius: 24px;
  padding: 10px 4px;
  margin: 40px 3px 0 3px;
}
.allScLogo img{
  width: 250px;
  height: auto;
}
footer{
  margin-top: 50px;
}
footer .footLogo{
  width: 100px;
  height: 100px;
  margin: 3rem 0;
  transition: var(--tran-04);
  margin-left: 8rem;
}
footer .footLogo:hover{
  transform: rotateY(360deg);
}
footer .footLine{
  width: 900px;
  height: 5px;
  background: var(--bar-color-light);
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  border-radius: 50px;
  margin: -1.5rem 0;
  transition: var(--tran-04);
}
footer .footLine:hover{
  background: var(--golden-color-txt);
}
footer .SCContainer{
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 4rem 0 0rem 0;
}
footer p{
  text-align: center;
  margin-top: 1rem;
}
@media screen and (max-width: 1200px){
  * {
    font-size: 17px;
  }
  h1{
    font-size: 40px;
  }h2{
    font-size: 30px;
  }h3{
    font-size: 20px;
  }h4{
    font-size: 15px;
  }h5{
    font-size: 10px;
  }
  button{
    padding: 0 5px;
    border-radius: 8px;
  }
  header .NBBox{
    height: 80px;
  }
  .NBBox img{
    width: 50px;
    height: 50px;
  }
  .NBBox .NBOption{
    gap: 10px;
  }
  .NBBox .NBBtn{
    gap: 10px;
  }
  .homePage{
    display: block;
  }
  .homePage .Pt2{
    margin-top: 2rem;
  }
  .Pt2 img{
    width: 300px;
    height: 300px;
  }
  .SCContainer span img{
    width: 38px;
  }
  .AllocImg img{
    margin-top: 40px;
    width: 85%;
  }
  .RMpic img{
    width: 85%;
  }
  .allScLogo img{
    width: 180px;
    height: auto;
  }
  footer .footLogo{
    width: 80px;
    height: 80px;;
    margin-left: 2rem;
  }
  footer .footLine{
    width: 85%;
  }
}

@media screen and (max-width: 825px){
  h1{
    font-size: 40px;
    text-wrap: wrap;
    padding: 0 0.5rem;
  }
  .NBBox .NBOption{
    list-style: none;
    gap: 0px;
    height: 88vh;
    position: absolute;
    left: 0;
    top: 4.4rem;
    width: 300px;
    text-align: center;
    background-image: url(./img/gif3.gif);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-right: 1px solid red;
    justify-content: center;
    transition: ease-out 0.5s;
  }
  .NBOption li{
    text-align: center;
    height: fit-content;
    margin: 0.5rem 4rem;
  }
  .NBBox .clicked{
    display: block;
    pointer-events: all;
    transition: var(--tran-04);
    transition: ease-out 0.5s;
  }
  .NBBox .unclicked{
    display: none;
    pointer-events: none;
    transition: var(--tran-04);
  }
  .NBBox #MenuBtn{
    display: block;
    pointer-events: auto;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  #MenuBtn .burgarBar{
    background: var(--bar-color-light);
    height: 4px;
    width: 34px;
    margin: 6px 0;
    border-radius: 25px;
  }
  #MenuBtn .active:nth-child(1){
    transform: rotate(40deg) translate(0.30em, 0.5em);
    transition: ease-out 0.5s;
  }
  #MenuBtn .active:nth-child(2){
    transform: scale(0.1);
    transition: ease-out 0.5s;
  }
  #MenuBtn .active:nth-child(3){
    transform: rotate(140deg) translate(-0.3em, 0.55em);
    transition: ease-out 0.5s;
  }
  .TokenPage .TPH4{
    padding-left: 1rem;
    text-wrap: wrap;
  }
}
@media screen and (max-width: 530px) {
  h1{
    font-size: 35px;
    text-wrap: wrap;
    padding: 0 0.1rem;
  }
  i{
    text-align: start;
    padding-left: 5px;
  }
  .TPH4 i{
    font-size: 20px;
  }
  body{
    background-size: 100% 200%;
  }
  .NBBox .NBBtn :nth-child(2){
    display: none;
  }
  .OVPH1 span{
    width: 40px;
  }
  .overviewPage .OVH34{
    padding: 0 1rem;
  }
  .TokenPage .TPH4{
    padding-left: 6rem;
    margin-top: 40px;
    overflow: hidden;
    text-wrap: wrap;
  }
  .TokenPage .TPH4{
    padding-left: 0.5rem;
  }
  .AllocImg img{
    width: 95%;
    height: auto;
  }
}
